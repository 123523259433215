// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

// import { canMatch as canMatchForAdmin } from './core/auth/admin.guard';
import { canLoad as canLoadForAdmin } from './core/auth/admin.guard';
import {
  canActivate as canActivateForAuth,
  /*canMatch as canMatchForAuth,*/
  canLoad as canLoadForAuth,
} from './core/auth/auth.guard';
import { QuestionnaireResponseEntrypointComponent } from './questionnaire-response/questionnaire-response-entrypoint/questionnaire-response-entrypoint.component';
import { QuestionnaireResponseResolver } from './questionnaire-response/questionnaire-response.resolver';
import { QuestionnaireResponseComponent } from './questionnaire-response/questionnaire-response/questionnaire-response.component';
import { NoClientComponent } from './ui/no-client/no-client.component';
import { NotFoundComponent } from './ui/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'admin',
    data: { title: null, breadcrumb: null },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    // FIXME: We should be using `canMatch` but `canMatch` guard runs on every route navigation. Where as `canLoad` that
    // we have been using before angular17 upgrade only runs once. We need to review our `canMatch` func declaration. ATM
    // when it runs on every route navigation, it fires off observable emissions that was previously assumed to only fire
    // once.
    // canMatch: [canMatchForAuth, canMatchForAdmin],
    canLoad: [canLoadForAuth, canLoadForAdmin],
  },
  {
    path: 'questionnaire/:questionnaireResponseId',
    component: QuestionnaireResponseEntrypointComponent,
    resolve: {
      questionnaireResponse: QuestionnaireResponseResolver,
    },
    runGuardsAndResolvers: 'always',
    canActivate: [canActivateForAuth],
    children: [
      {
        path: 'start',
        component: QuestionnaireResponseComponent,
        resolve: {
          questionnaireResponse: QuestionnaireResponseResolver,
        },
        runGuardsAndResolvers: 'always',
      },
    ],
  },
  {
    path: 'no-client',
    component: NoClientComponent,
  },
  {
    path: '',
    data: { title: null, breadcrumb: null },
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    // FIXME: We should be using `canMatch` but `canMatch` guard runs on every route navigation. Where as `canLoad` that
    // we have been using before angular17 upgrade only runs once. We need to review our `canMatch` func declaration. ATM
    // when it runs on every route navigation, it fires off observable emissions that was previously assumed to only fire
    // once.
    // canMatch: [canMatchForAuth],
    canLoad: [canLoadForAuth],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload',
  anchorScrolling: 'enabled',
  bindToComponentInputs: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
